import React, { Component } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import Layout from '../../components/layout';
import { media } from '../../tokens';

const JSPageContainer = styled.main`
  display: flex;
  width: 100%;
  padding: 10px;

  @media ${media.medium} {
    padding: 2rem;
  }
`

const SubjectTitle = styled.h1`
  font-weight: lighter;
  margin-bottom: 10px;
  font-size: 2rem;
`

class JSPage extends Component {
  render() { 
    return (
      <Layout>
        <SEO
          title="JavaScript - Infuse your web apps with Superpowers"
          description="JavaScript (JS) tutorials by Nowispow. Bring websites and applications to life by writing custom JS code. Whether you are a student or wanting to start a career in the tech field, these tutorials will help you."
        />
        <JSPageContainer>
          <SubjectTitle>JavaScript - Zero to Superhero</SubjectTitle>
        </JSPageContainer>
      </Layout>
    );
  }
}
 
export default JSPage;
